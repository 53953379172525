import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { loadStripe } from "@stripe/stripe-js"
export const PaintingTemplate = ({
    id,
    content,
    contentComponent,
    tags,
    collections,
    title,
    year,
    price,
    helmet,
    image,
    paintings,
    width,
    height
  }) => {
  const PostContent = contentComponent || Content;

  let otherPaintings = [];

    paintings.forEach((painting) => {
    // Not show itself
    if (painting.node.id === id) {
      return;
    }

    // Show only paintings which have one of the collections same as current painting
    for (let i = 0; i < collections.length; i++) {
      if (painting.node.frontmatter.collections && painting.node.frontmatter.collections.indexOf(collections[i]) !== -1) {
        otherPaintings.push((
            <div className="is-parent column is-4" key={painting.node.id}>
              <article>
                <div className="featured-thumbnail">
                  <Link to={painting.node.fields.slug}>
                    <PreviewCompatibleImage
                        imageInfo={{
                          image: painting.node.frontmatter.featuredimage,
                          alt: painting.node.frontmatter.title,
                        }}
                    />
                  </Link>
                </div>
              </article>
            </div>
        ));
        break;
      }
    }
  })

  // TODO: REFACTOR this, price should come from internal file to avoid modification on client side
  const buyNow = async (event) => {
    event.preventDefault();

    const response = await fetch('/.netlify/functions/create-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title: title,
        image: image,
        price: price,
        description: `${(width / 1).toFixed(1)} x ${(height / 1).toFixed(1)} in / ${width}" x ${height}". Taxes included`
        // description: `${(width / 2.54).toFixed(1)} x ${(height / 2.54).toFixed(1)} in / ${width} x ${height} cm. Free Shipping`
      }),
    }).then((res) => res.json());

    const stripe = await loadStripe(response.publishableKey);
    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    });

    if (error) {
      console.error(error);
    }
  }

  const style = {
    height: 'calc(90vh - 4rem)'
  }

  return (
    <div>
      <section className="section">
        {helmet || ''}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="columns is-vcentered">
                <div className="column is-8">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: image,
                      alt: title,
                      style: style,
                    }}
                    style={style}
                  />
                </div>
                <div className="column is-4">
                  <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                    {title} <sup><small>{year}</small></sup>
                  </h1>
                  {price && (<p>{price} CA$</p>)}
                  {(width && height) && (
                      <p>
                        {(width / 2.54).toFixed(0)}" x {(height / 2.54).toFixed(0)}" / {width} x {height} cm
                      </p>
                  )}
                  {price && (<button className="button is-large buy-button my-2" onClick={buyNow}>
                    Buy now!
                  </button>)}

                  {!price && (<button className="button is-large buy-button my-2 disabled">
                    Sold
                  </button>)}

                  <br />
                  <br />
                  <div className="my-2 py-2"><PostContent content={content} /></div>

                  {tags && tags.length ? (
                      <div>
                        <ul className="taglist">
                          {tags.map(tag => (
                              <li key={tag + `tag`}>
                                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                              </li>
                          ))}
                        </ul>
                      </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        <div className="container content" style={{paddingTop: 60}}>
          <div className="columns is-multiline is-centered is-gapless">
            {otherPaintings}
          </div>
        </div>
    </div>
  )
};

PaintingTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  year: PropTypes.number,
  helmet: PropTypes.object,
  image: PropTypes.object,
  id: PropTypes.string,
  price: PropTypes.number
};

const Painting = ({ data }) => {
  const { markdownRemark: post } = data;
  const { allMarkdownRemark } = data;

  return (
    <Layout>
      <PaintingTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Painting">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description} painted by Olena Isai`}
            />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={`${data.site.siteMetadata.url}
            ${post.frontmatter.ogimage.childImageSharp.fixed.src}`} />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        year={post.frontmatter.year}
        image={post.frontmatter.featuredimage}
        paintings={allMarkdownRemark.edges}
        width={post.frontmatter.width}
        height={post.frontmatter.height}
        id={post.id}
        price={post.frontmatter.price}
        collections={post.frontmatter.collections}
      />
    </Layout>
  )
};

Painting.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    allMarkdownRemark: PropTypes.object
  }),
};

export default Painting;

export const pageQuery = graphql`
  query PaintingByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        price
        year
        width
        height
        description
        tags
        collections
        order
        featuredimage: featuredimage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ogimage: featuredimage {
          childImageSharp {
            fixed(width: 600, height: 315, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___order, frontmatter___date] }
          filter: {frontmatter: {templateKey: {eq: "painting"}}}
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                collections
                featuredimage {
                  childImageSharp {
                    fixed(width: 350, height: 350, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`;
